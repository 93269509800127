<template>
  <div class="aboutus">
    <img class="top-title" src="/imgs/aboutus/top-title-img.png" alt>
    <div class="box">
      <img src="/imgs/aboutus/box-title1.png" alt>
      <div class="box-content">
        <div class="content-box">
          <img src="/imgs/aboutus/box-content-bg.png" alt>
          <div class="content">
            <div class="content-title">
              生而全球化
              <span>Born to be Global</span>
            </div>
            <div
              class="text"
            >无界数字科技有限公司，是一家专注去平台中心化、分布式自主经营的移动互联网数字技术SaaS研发公司，为各行各业中小微企业、个体工商户、经营创业者数字化转型升级赋能并提供整套解决方案。其自主创新研发的无界SaaS，自带市面上所有商业模式，可赋能加持全球各行各业中小微企业、个体工商户、经营创业者数字化转型升级，帮助中小微企业、个体工商户、经营创业者提升经营维度，中小微企业、个体工商户、经营创业者无需设计商业模式，无需开发技术软件搭建平台，无需营运维护，只要下载使用无界SaaS使用无界云仓孵化，就能实现数字化无边界经营创业。</div>
          </div>
        </div>
      </div>
    </div>
    <div class="box">
      <img src="/imgs/aboutus/box-title2.png" alt>
      <div class="box-content">
        <div class="content-box">
          <img src="/imgs/aboutus/box-content-bg1.png" alt>
          <div class="content left">
            <div class="content-title">
              • 核心使命、理念
              <span>/ Core Mission Idea</span>
            </div>
            <div class="text">打造公平贸易，推动社会诚信。</div>
            <div class="content-title">
              • 战略定位
              <span>/ Strategic Positioning</span>
            </div>
            <div class="text">为各行各业的中小微企业、个体工商户、创业经营者赋能加持，数字化转型升级，提供一整套解决方案！</div>
            <div class="content-title">
              • 商业模式
              <span>/ Business Model</span>
            </div>
            <div class="text">无界SaaS是集“云、网、店、端、人、单、群、社交、自媒体社交”等合一的复合经济体。</div>
            <div class="content-title">
              • 核心战术
              <span>/ Core Tactics</span>
            </div>
            <div
              class="text"
            >采用独创的无界SaaS作为赋能加持的全新物种，帮助各行各业的中小微企业、个体工商户、创业经营者数字化转型升级，用无界批发、无界零售、社交、分享、自媒体等无边界链接一切资源，协同打造数据流量价值体系，构建永久属于数字店的商群诚信生态。</div>
            <div class="content-title">
              • 经营策略
              <span>/ Business Strategy</span>
            </div>
            <div
              class="text"
            >去平台中心化、分布式经营，以无界数字店为中心，以Uni系列无界数字云仓作为后盾，运用不可篡改的区块链底层数据溯源技术原理，从架构的根本上帮助无界数字店解决经营和数据安全问题。</div>
            <div class="content-title">
              • 市场口号
              <span>/ Market Slogan</span>
            </div>
            <div class="text">
              <div class>V UP！做更好的自己！</div>
              <div class>让经营创业更简单，更容易！</div>
            </div>
            <div class="content-title">
              • 公司及市场统一文化
              <span>/ Unified Culture of Company and Market</span>
            </div>
            <div class="text">享无界，行无界！Link to better！</div>
            <div class="content-title">
              • 共同愿景
              <span>/ Shared Vision</span>
            </div>
            <div class="text">打造全球无边界公平贸易诚信生态，帮助全球的中小微企业、个体工商户、创业经营者实现无边界经营、无边界创业！</div>
          </div>
        </div>
      </div>
    </div>
    <div class="box">
      <img src="/imgs/aboutus/box-title3.png" alt>
      <img src="/imgs/aboutus/box-content-bg2.png" alt>
    </div>
    <div class="grey-box">
      <div class="bag" />
      <div class="content">
        <div class="text-item">
          <div class="title">01、去平台中心化，分布式自主经营</div>
          <div
            class="text"
          >市面上几乎所有的App都是为中心平台收集数据而服务的移动终端，而无界SaaS是打破平台中心化，为各行各业的中小微企业、个体工商户、创业经营者数字化转型升级提供整套解决方案，赋能加持他们提升经营维度，打造数据价值体系，构建商群诚信生态，帮助他们做更好的自己。你只要使用无界SaaS，你就是平台，你就是中心，你就是CEO，你就是自主经营的复合经济体！包括各种资金都是采用去平台中心化、分布式自主经营的监管方式。</div>
        </div>
        <div class="text-item">
          <div class="title">02、不改变对方经营状况，赋能加持共享共赢</div>
          <div
            class="text"
          >市面上几乎所有的社交电商平台或项目，都是要改变对方去参与经营。无界SaaS不改变任何线上线下中小微企业、个体工商户、创业经营者的经营状况，只是赋能加持他们，提升他们的经营维度，实现数字化转型升级，让其额外增加无边界利润和数据价值收益，实现数据共享、利润共享、价值共享！</div>
        </div>
        <div class="text-item">
          <div class="title">03、智慧分工，协同合作；万物、万企、万商、万人互联</div>
          <div class="text">
            <p>Link是无界SaaS特用专业词，它与to、推荐和推广有本质的区别。to、推荐和推广是单向的，而Link是多维互动的利润和价值的链接。</p>
            <p>无界SaaS具有四大链接力，分别是企业商户资源与企业商户资源的链接（BLB）、企业商户资源与个体资源的链接（BLC）、个体资源与企业商户资源的链接（CLB）、个体资源与个体资源的链接（CLC）。</p>
            <p>中小微企业、个体工商户、创业经营者用无界SaaS除了自主经营自己的商品资源之外，更重要的是还可以链接一切资源，从而赚取到各行各业的利润和数据流量价值。</p>
            <p>假如你是一位餐饮店经营者。</p>
            <p>如果你入驻某团平台，你将获得订单和返利；</p>
            <p>如果你使用无界SaaS，你可以用它链接服装店。</p>
            <p>服装店每完成一笔订单，你都将赚取利润和数据价值；</p>
            <p>服装店链接其它的商户，你也能赚取到其它商户的利润；他们裂变链接也能赚取到各行各业的利润，并获得数据价值。</p>
            <p>一个简单的链接裂变，你就能获得无边界的利润来源和数据价值。</p>你还可以去链接同行，同行的经营和链接裂变产生的利润和数据价值都可以共享获得，打破了“同行是冤家”的定律。无边界链接的一切资源，不需要在中心平台上完成，只需使用无界SaaS就可以自己链接和裂变完成。
          </div>
        </div>
        <div class="text-item">
          <div class="title">04、利润和数据价值的无边界来源</div>
          <div class="text">
            传统线上线下企业商户的利润仅来源于自己的商品资源销售、没有自己的数据价值；而中心化平台是整合资源获得数据价值，入驻中心平台的企业商户只能按照中心平台的规定，去销售产品来获得利润，没有数据价值；社交电商的中心平台经营推广者也只是用移动端App，推广平台内的商品来获取消费返利，没有数据价值，且角色单一、利润来源窄、经营维度低。
            <p>而各行各业的中小微企业商户和个体经营创业者使用无界SaaS，可以自主上架各种种类商品、自主定价、自主定利润V值。无论是买、卖、链接，都可以获得利润和数据流量价值。所有的合作伙伴都可以共享商业利润、共享商业数据，共享商业价值。</p>
          </div>
        </div>
        <div class="text-item">
          <div class="title">05、构建商群诚信生态</div>
          <div class="text">
            <p>由于无界SaaS是去平台中心化，因此每一个中小微企业商户和个体经营创业者都是平台，都是分布式经营在做自己。中小微企业商户和个体经营创业者预定的是自己云仓买、卖、链接数据流量1000V，这是对自己诚信的承诺，也保证了中小微企业、个体工商户、创业经营者的每一笔买、卖、链接交易都是真实有效的，避免了虚假刷单现象。</p>同时，企业商户链接的其它企业商户资源，无论是商品上架云仓、商品价格制定，还是利润V值都由企业商户自主决定。各行各业的中小微企业、个体工商户、创业经营者预定1000V买、卖、链接数据流量，也是中小微企业、个体工商户、创业经营者对自己的诚信作出承诺。当商群内的各行各业中小微企业、个体工商户、创业经营者都对自己买、卖、链接的交易诚信担保达成共识，并协同合作时，商群诚信生态就形成了。
          </div>
        </div>
      </div>
    </div>
    <div class="box">
      <img src="/imgs/aboutus/box-title4.png" alt>
      <div class>
        <div class="img-title">营业执照</div>
      </div>
      <div class="diepic diepic1">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide" @click="picturepreview('businesslicense',0)">
              <img src="/imgs/aboutus/yingye.jpg" alt>
            </div>
            <div class="swiper-slide" @click="picturepreview('businesslicense',1)">
              <img src="/imgs/aboutus/yingye1.jpg" alt>
            </div>
            <div class="swiper-slide" @click="picturepreview('businesslicense',2)">
              <img src="/imgs/aboutus/yingye2.jpg" alt>
            </div>
            <div class="swiper-slide" @click="picturepreview('businesslicense',3)">
              <img src="/imgs/aboutus/yingye3.jpg" alt>
            </div>
          </div>
        </div>
        <div class="swiper-pagination pagination" />
      </div>
    </div>
    <div class="box1">
      <div class>
        <div class="img-title">商标注册证</div>
      </div>
      <div class="diepic diepic2">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide" @click="picturepreview('trademark',0)">
              <img src="/imgs/aboutus/trademark1.jpg" alt>
            </div>
            <div class="swiper-slide" @click="picturepreview('trademark',1)">
              <img src="/imgs/aboutus/trademark2.jpg" alt>
            </div>
            <div class="swiper-slide" @click="picturepreview('trademark',2)">
              <img src="/imgs/aboutus/trademark3.jpg" alt>
            </div>
            <div class="swiper-slide" @click="picturepreview('trademark',3)">
              <img src="/imgs/aboutus/trademark4.jpg" alt>
            </div>
            <div class="swiper-slide" @click="picturepreview('trademark',4)">
              <img src="/imgs/aboutus/trademark5.jpg" alt>
            </div>
            <div class="swiper-slide" @click="picturepreview('trademark',5)">
              <img src="/imgs/aboutus/trademark6.jpg" alt>
            </div>
            <div class="swiper-slide" @click="picturepreview('trademark',6)">
              <img src="/imgs/aboutus/trademark7.jpg" alt>
            </div>
          </div>
        </div>
        <div class="swiper-pagination1 pagination" />
      </div>
    </div>
    <div class="box1">
      <div class>
        <div class="img-title">知识产权证书</div>
      </div>
      <div class="diepic diepic3">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide" @click="picturepreview('propertyright',0)">
              <img src="/imgs/aboutus/knowledge1.jpg" alt>
            </div>
            <div class="swiper-slide" @click="picturepreview('propertyright',1)">
              <img src="/imgs/aboutus/knowledge2.jpg" alt>
            </div>
            <div class="swiper-slide" @click="picturepreview('propertyright',2)">
              <img src="/imgs/aboutus/knowledge3.jpg" alt>
            </div>
            <div class="swiper-slide" @click="picturepreview('propertyright',3)">
              <img src="/imgs/aboutus/knowledge4.jpg" alt>
            </div>
            <div class="swiper-slide" @click="picturepreview('propertyright',4)">
              <img src="/imgs/aboutus/knowledge5.jpg" alt>
            </div>
            <div class="swiper-slide" @click="picturepreview('propertyright',5)">
              <img src="/imgs/aboutus/knowledge6.jpg" alt>
            </div>
            <div class="swiper-slide" @click="picturepreview('propertyright',6)">
              <img src="/imgs/aboutus/knowledge7.jpg" alt>
            </div>
            <div class="swiper-slide" @click="picturepreview('propertyright',7)">
              <img src="/imgs/aboutus/knowledge8.jpg" alt>
            </div>
            <div class="swiper-slide" @click="picturepreview('propertyright',8)">
              <img src="/imgs/aboutus/knowledge9.jpg" alt>
            </div>
            <div class="swiper-slide" @click="picturepreview('propertyright',9)">
              <img src="/imgs/aboutus/knowledge10.jpg" alt>
            </div>
            <div class="swiper-slide" @click="picturepreview('propertyright',10)">
              <img src="/imgs/aboutus/knowledge11.jpg" alt>
            </div>
            <div class="swiper-slide" @click="picturepreview('propertyright',11)">
              <img src="/imgs/aboutus/knowledge12.jpg" alt>
            </div>
            <div class="swiper-slide" @click="picturepreview('propertyright',12)">
              <img src="/imgs/aboutus/knowledge13.jpg" alt>
            </div>
            <div class="swiper-slide" @click="picturepreview('propertyright',13)">
              <img src="/imgs/aboutus/knowledge14.jpg" alt>
            </div>
            <div class="swiper-slide" @click="picturepreview('propertyright',14)">
              <img src="/imgs/aboutus/knowledge15.jpg" alt>
            </div>
            <div class="swiper-slide" @click="picturepreview('propertyright',15)">
              <img src="/imgs/aboutus/knowledge16.jpg" alt>
            </div>
            <div class="swiper-slide" @click="picturepreview('propertyright',16)">
              <img src="/imgs/aboutus/knowledge17.jpg" alt>
            </div>
          </div>
        </div>
        <div class="swiper-pagination2 pagination" />
      </div>
    </div>
    <div class="box1">
      <div class>
        <div class="img-title">ICP/EDI证书</div>
      </div>
      <div class="img-box">
        <img src="/imgs/aboutus/EDI1.jpg" alt>
        <img src="/imgs/aboutus/EDI2.jpg" alt>
      </div>
    </div>
    <div class="box1">
      <div class>
        <div class="img-title">企业证书</div>
      </div>
      <div class="img-box">
        <img src="/imgs/aboutus/minorenterprises.jpg" alt @click="picturepreview('certificate',0)">
        <img
          src="/imgs/aboutus/enterprisecertification.jpg"
          alt
          @click="picturepreview('certificate',1)"
        >
      </div>
    </div>
    <div class="box1">
      <div class>
        <div class="img-title">其他证书</div>
      </div>
      <div class="img-box">
        <img src="/imgs/aboutus/other.jpg" alt @click="picturepreview('works',0)">
      </div>
    </div>
    <picturepreview v-if="ispicturepreview" :imgs="imgsList" :initial-slide="index" @close="close" />
  </div>
</template>

<script>
import Swiper from 'swiper'
import '@/assets/css/style.css'
import picturepreview from './picturepreview.vue'
export default {
  components: { picturepreview },
  data() {
    return {
      index: 0,
      imgsList: [],
      ispicturepreview: false,
      businesslicense: [
        '/imgs/aboutus/businesslicense1.jpg',
        '/imgs/aboutus/businesslicense2.jpg',
        '/imgs/aboutus/businesslicense3.jpg',
        '/imgs/aboutus/businesslicense4.jpg'
      ],
      trademark: [
        '/imgs/aboutus/trademark1-1.jpg',
        '/imgs/aboutus/trademark1-2.jpg',
        '/imgs/aboutus/trademark1-3.jpg',
        '/imgs/aboutus/trademark1-4.jpg',
        '/imgs/aboutus/trademark1-5.jpg',
        '/imgs/aboutus/trademark1-6.jpg',
        '/imgs/aboutus/trademark1-7.jpg'
      ],
      propertyright: [
        '/imgs/aboutus/20230313102226653_0002.jpg',
        '/imgs/aboutus/20230313102226653_0003.jpg',
        '/imgs/aboutus/20230313102226653_0004.jpg',
        '/imgs/aboutus/20230313102226653_0005.jpg',
        '/imgs/aboutus/20230313102226653_0006.jpg',
        '/imgs/aboutus/20230313102226653_0007.jpg',
        '/imgs/aboutus/20230313102226653_0008.jpg',
        '/imgs/aboutus/20230313102226653_0009.jpg',
        '/imgs/aboutus/20230313102226653_0010.jpg',
        '/imgs/aboutus/20230313102226653_0011.jpg',
        '/imgs/aboutus/20230313102226653_0012.jpg',
        '/imgs/aboutus/20230313102226653_0013.jpg',
        '/imgs/aboutus/20230313102226653_0014.jpg',
        '/imgs/aboutus/20230313102226653_0015.jpg',
        '/imgs/aboutus/20230313102226653_0016.jpg',
        '/imgs/aboutus/20230313102226653_0017.jpg'
      ],
      works: ['/imgs/aboutus/20230313102226653_0001.jpg'],
      certificate: [
        '/imgs/aboutus/certificate1.jpg',
        '/imgs/aboutus/certificate2.jpg'
      ]
    }
  },
  created() {
    window.scrollTo(0, 0)
  },
  mounted() {
    new Swiper('.diepic1 .swiper-container', {
      watchSlidesProgress: true,
      slidesPerView: 'auto',
      centeredSlides: true,
      loop: true,
      loopedSlides: 5,
      autoplay: 3000,
      pagination: '.swiper-pagination',
      paginationClickable: true,
      slideToClickedSlide: true,
      onProgress: function(swiper, progress) {
        for (let i = 0; i < swiper.slides.length; i++) {
          var slide = swiper.slides.eq(i)
          var slideProgress = swiper.slides[i].progress
          let modify = 1
          if (Math.abs(slideProgress) > 1) {
            modify = (Math.abs(slideProgress) - 1) * 0.3 + 1
          }
          const translate = slideProgress * modify * 201 + 'px'
          const scale = 1 - Math.abs(slideProgress) / 3
          const zIndex = 999 - Math.abs(Math.round(10 * slideProgress))
          slide.transform('translateX(' + translate + ') scale(' + scale + ')')
          slide.css('zIndex', zIndex)
          slide.css('opacity', 1)
          if (Math.abs(slideProgress) > 3) {
            slide.css('opacity', 0)
          }
        }
      },
      onSetTransition: function(swiper, transition) {
        for (var i = 0; i < swiper.slides.length; i++) {
          var slide = swiper.slides.eq(i)
          slide.transition(transition)
        }
      },
      // 处理分页器bug
      onSlideChangeStart: function(swiper) {
        if (swiper.activeIndex === 4) {
          swiper.bullets.eq(9).addClass('swiper-pagination-bullet-active')
          console.log(swiper.bullets.length)
        }
      }
    })
    new Swiper('.diepic2 .swiper-container', {
      watchSlidesProgress: true,
      slidesPerView: 'auto',
      centeredSlides: true,
      loop: true,
      loopedSlides: 5,
      autoplay: 3000,
      pagination: '.swiper-pagination1',
      paginationClickable: true,
      slideToClickedSlide: true,
      onProgress: function(swiper, progress) {
        for (let i = 0; i < swiper.slides.length; i++) {
          var slide = swiper.slides.eq(i)
          var slideProgress = swiper.slides[i].progress
          let modify = 1
          if (Math.abs(slideProgress) > 1) {
            modify = (Math.abs(slideProgress) - 1) * 0.3 + 1
          }
          const translate = slideProgress * modify * 201 + 'px'
          const scale = 1 - Math.abs(slideProgress) / 3
          const zIndex = 999 - Math.abs(Math.round(10 * slideProgress))
          slide.transform('translateX(' + translate + ') scale(' + scale + ')')
          slide.css('zIndex', zIndex)
          slide.css('opacity', 1)
          if (Math.abs(slideProgress) > 3) {
            slide.css('opacity', 0)
          }
        }
      },
      onSetTransition: function(swiper, transition) {
        for (var i = 0; i < swiper.slides.length; i++) {
          var slide = swiper.slides.eq(i)
          slide.transition(transition)
        }
      },
      // 处理分页器bug
      onSlideChangeStart: function(swiper) {
        if (swiper.activeIndex === 4) {
          swiper.bullets.eq(9).addClass('swiper-pagination-bullet-active')
          console.log(swiper.bullets.length)
        }
      }
    })
    new Swiper('.diepic3 .swiper-container', {
      watchSlidesProgress: true,
      slidesPerView: 'auto',
      centeredSlides: true,
      loop: true,
      loopedSlides: 5,
      autoplay: 3000,
      pagination: '.swiper-pagination2',
      paginationClickable: true,
      slideToClickedSlide: true,
      onProgress: function(swiper, progress) {
        for (let i = 0; i < swiper.slides.length; i++) {
          var slide = swiper.slides.eq(i)
          var slideProgress = swiper.slides[i].progress
          let modify = 1
          if (Math.abs(slideProgress) > 1) {
            modify = (Math.abs(slideProgress) - 1) * 0.3 + 1
          }
          const translate = slideProgress * modify * 201 + 'px'
          const scale = 1 - Math.abs(slideProgress) / 3
          const zIndex = 999 - Math.abs(Math.round(10 * slideProgress))
          slide.transform('translateX(' + translate + ') scale(' + scale + ')')
          slide.css('zIndex', zIndex)
          slide.css('opacity', 1)
          if (Math.abs(slideProgress) > 3) {
            slide.css('opacity', 0)
          }
        }
      },
      onSetTransition: function(swiper, transition) {
        for (var i = 0; i < swiper.slides.length; i++) {
          var slide = swiper.slides.eq(i)
          slide.transition(transition)
        }
      },
      // 处理分页器bug
      onSlideChangeStart: function(swiper) {
        if (swiper.activeIndex === 4) {
          swiper.bullets.eq(9).addClass('swiper-pagination-bullet-active')
          console.log(swiper.bullets.length)
        }
      }
    })
  },
  methods: {
    picturepreview(type, index) {
      this.index = index
      if (type === 'businesslicense') {
        this.imgsList = this.businesslicense
      } else if (type === 'trademark') {
        this.imgsList = this.trademark
      } else if (type === 'propertyright') {
        this.imgsList = this.propertyright
      } else if (type === 'works') {
        this.imgsList = this.works
      } else if (type === 'certificate') {
        this.imgsList = this.certificate
      }
      this.ispicturepreview = true
    },
    close() {
      this.ispicturepreview = false
    }
  }
}
</script>

<style lang="scss" scoped>
.aboutus {
  padding-bottom: 0.3rem;
  .top-title {
    margin-top: 0.3rem;
  }
  .box {
    text-align: left;
    margin-top: 0.3rem;
    position: relative;
    z-index: 1;
    .box-content {
      text-align: center;
      .content-box {
        position: relative;
        display: inline-block;
        .content {
          position: absolute;
          right: 0;
          top: 0;
          text-align: left;
          width: 54%;
          padding: 0.1rem;
          .content-title {
            font-size: 0.12rem;
            font-weight: bold;
            margin-top: 0.08rem;
            span {
              color: #656565;
              font-size: 16px;
              font-weight: 100;
            }
          }
          .text {
            line-height: 0.15rem;
            margin-top: 0.05rem;
          }
        }
        .left {
          left: 0;
          margin-top: 0.5rem;
          width: 59%;
          .content-title {
            font-size: 0.1rem;
          }
          .text {
            margin-top: 0.05rem;
          }
        }
      }
    }
    & > img {
      margin-bottom: 0.2rem;
    }
  }
  .grey-box {
    text-align: left;
    padding: 0.2rem;
    padding-top: 0.5rem;
    margin-top: -0.6rem;
    position: relative;
    .bag {
      background-color: #f2f6ff;
      position: absolute;
      top: 0;
      bottom: 0;
      left: -100%;
      right: -100%;
      z-index: 0;
    }
    .content {
      position: relative;
      .text-item {
        margin-top: 0.2rem;
        .title {
          font-size: 0.1rem;
          font-weight: bold;
        }
        .text {
          line-height: 0.2rem;
          margin-top: 0.1rem;
        }
      }
    }
  }
  .img-title {
    margin: 0.1rem 0.2rem;
    box-shadow: 7px 7px 6px #f2f6ff;
    display: inline-block;
  }
  .img-box {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0.2rem;
  }
  .box1 {
    text-align: left;
  }
  .diepic2,
  .diepic3 {
    .swiper-slide {
      width: 40%;
    }
  }
}
</style>
